#home {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .top-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;
        
        .wrap {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }
}