@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
}

.container {
    width: 100vw;
    min-height: 100vh;
    padding: 20px 30px 20px 30px;
}

@import './pages/pages';
@import './components/popup.scss'