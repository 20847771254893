#login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .title {
        font-size: 3rem;
    }

    .input {
        width: 100%;
        max-width: 300px;
    }
}